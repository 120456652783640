






import {
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
  SetupContext,
} from '@vue/composition-api';

import { ChatStatus } from '@/infrastructure/store/modules/Events';
import { MessageFromPublicChat } from '@/infrastructure/services/quickblox/specs';

export default defineComponent({
  name: 'DialogList',
  setup(props, { root }: SetupContext) {
    const state = reactive({
      dialogs: [],
      showWindow: false,
      firstLoad: true,
    });

    const calculated = {
      currentDialogs: computed(() => {
        return root.$store.getters['events/messages'];
      }),
      chatStatus: computed(() => {
        return root.$store.getters['events/status'];
      }),
      userIsMaster: computed(() => {
        return root.$store.getters['events/userIsMaster'];
      }),
    };

    watch(
      [calculated.currentDialogs, calculated.userIsMaster],
      ([dialogs, isMaster]) => {
        if (dialogs.length > 0) {
          if (state.firstLoad) {
            state.firstLoad = false;
            root.$store.dispatch('events/retrieveDialogs');
          }
          state.dialogs = dialogs.map(
            (dialog: { userId: number; message: MessageFromPublicChat }) => {
              const {
                userId,
                message: {
                  body,
                  extension: { fullName, date },
                },
              } = dialog;
              return {
                action: body,
                player: {
                  name: fullName,
                  id: userId,
                  date,
                },
              };
            },
          );
        }
      },
    );

    watch(calculated.chatStatus, (status: ChatStatus) => {
      if (status === ChatStatus.joined) {
        state.showWindow = true;
        root.$store.dispatch('events/sendAndRetrieveMessages', {
          message: 'ingress',
        });
      }
    });

    return {
      ...calculated,
      ...toRefs(state),
    };
  },
});
